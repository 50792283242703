export const navList = [
  {
    id: "1",
    title: "개발 솔루션",
    name: "development-solution",
    imgStyle: { opacity: 100 },
    children: [
      {
        id: "1-1",
        path: "/flatform-develope",
        name: "flatform-develope",
        title: "플랫폼 개발",
      },
      {
        id: "1-2",
        path: "/smart-solution",
        name: "smart-solution",
        title: "스마트 솔루션",
      },
    ],
  },
  {
    id: "2",
    title: "창업 컨설팅",
    name: "startup-consulting",
    imgStyle: { opacity: 10 },
    children: [
      {
        id: "2-1",
        path: "/startup-marketing-branding",
        name: "startup-marketing-branding",
        title: "창업 마케팅 / 브랜딩",
      },
      {
        id: "2-2",
        path: "/rd-ip-consulting",
        name: "rd-ip-consulting",
        title: "R&D / IP 컨설팅",
      },
    ],
  },
  {
    id: "3",
    title: "교육 프로그램",
    name: "education-program",
    imgStyle: { opacity: 20 },
    children: [
      {
        id: "3-1",
        path: "/ai-lifelong-education",
        name: "ai-lifelong-education",
        title: "AI 평생교육원",
      },
      {
        id: "3-2",
        path: "/chungnam-venture-education",
        name: "chungnam-venture-education",
        title: "충남창업보육협회",
      },
      {
        id: "3-3",
        path: "/ourim-makers",
        name: "ourim-makers",
        title: "어울림 메이커스",
      },
      {
        id: "3-4",
        path: "/future-education-culture",
        name: "future-education-culture",
        title: "미래교육문화협회",
      },
    ],
  },
];

export const sectionList = [
  {
    id: 1,
    name: "미래를 보는 마음",
    path: "/",
  },
  {
    id: 2,
    name: "ABOUT",
    path: "/",
  },
  {
    id: 3,
    name: "WE WORK",
    path: "/",
  },
];

export const solutionCardList = [
  {
    id: 1,
    category: "development",
    sub: "기관과 기업을 위한",
    title: "AI솔루션",
  },
  {
    id: 2,
    category: "consulting",
    sub: "예비 / 초기 창업자를 위한",
    title: "AI컨설팅",
  },
  {
    id: 3,
    category: "consulting",
    sub: "예비 / 초기 창업자를 위한",
    title: "AI컨설팅",
  },
];

export const serviceList = [
  {
    id: 1,
    title: "플랫폼 및 자동화\n솔루션 제안",
    titleColor: "future-blue-400",
    icon: "blue",
    itemList: [
      {
        subtitle: "플랫폼 개발 & IT기업화",
        description: "맞춤형 소프트웨어로 생산성 증대",
      },
      {
        subtitle: "스마트 기업 & 팩토리",
        description: "경영 및 첨단 자동화 솔루션 제안",
      },
      {
        subtitle: "콘텐츠 개발 & 글로벌화",
        description: "인플루언서 활용 콘텐츠 개발로 글로벌시장 공략",
      },
    ],
  },
  {
    id: 2,
    title: "마케팅과 브랜딩\n자금조달 까지",
    titleColor: "future-orange-400",
    icon: "orange",
    itemList: [
      {
        subtitle: "보장된 마케터 및 인플루언서",
        description:
          '인플루언서 기반 제휴광고 및 "실전매출육성" 마케팅프로그램',
      },
      {
        subtitle: "가치창출 브랜딩 디자인",
        description: "한국 디자인 진흥원 컨설턴트의 브랜딩제안",
      },
      {
        subtitle: "자금&상품 판로 개척",
        description: "R&D 지원사업 전문가 & 자문 변리사의 기술영업 매출혁신",
      },
    ],
  },
  {
    id: 3,
    title: "다양한 분야의\nAI 교육사업 컨설팅",
    titleColor: "future-green-500",
    icon: "green",
    itemList: [
      {
        subtitle: "창업교육 & 평생교육원",
        description: "연령제한 없는 AI 평생교육 프로그램",
      },
      {
        subtitle: "충남 창업 보육협회",
        description: "대학&유관 맞춤형 학생/교수 대상 AI 기술교육",
      },
      {
        subtitle: "힐링&체험교육",
        description:
          "4차산업&자연조화형 힐링캠프 집앞까지 찾아가는 이동형 힐링캠프",
      },
    ],
  },
];

export const contactList = [
  {
    id: 1,
    text: "퓨처마인드",
  },
  {
    id: 2,
    text: "충청남도 천안시 동남구 청수14로 96, 7층 713-A호",
  },
  {
    id: 3,
    text: "041.575.8729",
  },
  {
    id: 4,
    text: "master@futuremind.com",
  },
  {
    id: 5,
    text: "© 2024 futuremind All Rights Reserved.",
  },
];

export const partnerList = [
  {
    id: 1,
    alt: "호서대학교",
  },
  {
    id: 2,
    alt: "백석문화대학교",
  },
  {
    id: 3,
    alt: "방위사업청",
  },
  {
    id: 4,
    alt: "통일부",
  },
  {
    id: 5,
    alt: "한국콘텐츠진흥원",
  },
  {
    id: 6,
    alt: "충청남도",
  },
  {
    id: 7,
    alt: "소상공인시장진흥공단",
  },
  {
    id: 8,
    alt: "남서울대학교",
  },
  {
    id: 9,
    alt: "천안시",
  },
  {
    id: 10,
    alt: "삼성중공업",
  },
  {
    id: 11,
    alt: "경기도",
  },
  {
    id: 12,
    alt: "천안문화재단",
  },
];

export const mainTitleList = [
  {
    subTitle: {
      text: "Connecting dot",
      color: "text-future-green-400",
    },
    title1: {
      text: '"AI" 하나로 모든 것을',
      color: "text-white",
    },
    title2: {
      text: "연결시킵니다",
      color: "text-white",
    },
  },
  {
    subTitle: {
      text: "Connecting future",
      color: "text-future-purple-600",
    },
    title1: {
      text: "미래를 보는 마음",
      color: "text-white",
    },
    title2: {
      text: "퓨처마인드",
      color: "text-white",
    },
  },
];

// sub

export const subPageContentList = [
  // 플랫폼 개발 페이지
  {
    id: "1-1",
    mainPage: "development-solution",
    page: "flatform-develope",
    title: "플랫폼 개발",
    infoList: [
      {
        mainTitle: "웹디자인 솔루션",
        mainDescription:
          "웹디자인은 기업의 디지털 플랫폼 전환의 첫 단계입니다. 사용자의 경험(UX)과 인터페이스를 최적화하여 고객과의 소통을 원활하게 하는 데 중점을 둡니다.",
        subList: [
          {
            title: "기업 분석",
            description:
              "웹사이트를 통해 제공할 서비스의 목적과 타겟 사용자를 파악합니다. 이를 통해 기업의 핵심 가치를 반영한 디자인 설계를 시작합니다.",
          },
          {
            title: "UX/UI 설계",
            description:
              "고객의 웹사이트 사용 경험을 개선하기 위해 직관적이고 반응형 디자인을 채택합니다. 이는 사용자 만족도를 높이고 고객 유입을 극대화하는 필수 요소입니다.",
          },
          {
            title: "웹사이트 구축 및 운영",
            description:
              "웹사이트를 구축한 후, 각종 분석 도구를 사용하여 트래픽, 사용자 행동, 사이트 성과를 지속적으로 모니터링하여 유지 및 개선 작업을 진행합니다.",
          },
        ],
      },
      {
        mainTitle: "AI 챗봇 솔루션",
        mainDescription:
          "AI 챗봇은 기업의 고객 대응 효율성을 높이는 핵심 도구입니다. 실시간 상담과 고객요청 처리를 자동화함으로써 빠르고 정확한 서비스를 제공합니다.",
        subList: [
          {
            title: "고객 요구 분석",
            description:
              "기업의 고객 서비스에서 자주 발생하는 문의 유형과 해결 방법을 분석합니다. 이를 통해 챗봇에 필요한 질문과 답변 시나리오를 설정합니다.",
          },
          {
            title: "챗봇 개발",
            description:
              "AI 챗봇은 자연어 처리(NLP) 기능을 통해 사용자와 원활하게 소통할 수 있도록 개발됩니다. 이를 통해 사용자 만족도를 높이고, 실시간 대응이 가능합니다.",
          },
          {
            title: "테스트 및 배포",
            description:
              "챗봇을 실제 환경에서 테스트하여 오류를 수정하고, 배포 후 지속적인 학습을 통해 챗봇의 성능을 개선합니다.",
          },
        ],
      },
      {
        mainTitle: "AR 키오스크 솔루션",
        mainDescription:
          "AR 키오스크는 사용자에게 혁신적인 경험을 제공하며, 기업의 디지털 전환을 가속화하는 중요한 도구입니다. 특히, 교육, 홍보, 마케팅에서 활용도가 높습니다.",
        subList: [
          {
            title: "AR 콘텐츠 기획",
            description:
              "기업이 제공하는 상품이나 서비스를 시각적으로 체험할 수 있는 AR 콘텐츠를 기획합니다. 고객이 상품을 실시간으로 체험할 수 있도록 정보를 제공합니다.",
          },
          {
            title: "하드웨어 및 소프트웨어 통합",
            description:
              "키오스크 디바이스와 AR 콘텐츠를 통합하여, 사용자가 직관적으로 접근하고 체험할 수 있는 환경을 조성합니다.",
          },
          {
            title: "AR 키오스크 운영 및 피드백 반영",
            description:
              "설치된 키오스크의 사용자 경험을 분석하고, 지속적인 업데이트와 개선을 통해 고객 만족도를 극대화합니다.",
          },
        ],
      },
    ],
  },
  // 스마트 솔루션 페이지
  {
    id: "1-2",
    mainPage: "development-solution",
    page: "smart-solution",
    title: "스마트 솔루션",
    infoList: [
      {
        mainTitle: "ERP 솔루션",
        mainDescription:
          "ERP(Enterprise Resource Planning)는 기업의 모든 자원과 데이터를 통합 관리하여 효율적인 경영을 지원합니다.",
        subList: [
          {
            title: "기존 시스템 분석",
            description:
              "현재 사용 중인 시스템과 관리 방식을 분석하여 통합 가능한 기능을 파악하고, ERP를 도입할 수 있는 기회를 확인합니다.",
          },
          {
            title: "ERP 시스템 맞춤 설계",
            description:
              "기업의 필요에 맞는 ERP 모듈을 설계하고, 생산, 재무, 인사 등 다양한 부서를 연결합니다.",
          },
          {
            title: "시스템 통합 및 운영",
            description:
              "ERP 시스템을 도입한 후 모든 부서 간의 데이터를 실시간으로 공유하여, 의사결정이 신속하게 이루어지도록 지원합니다.",
          },
        ],
      },
      {
        mainTitle: "CMS 솔루션",
        mainDescription:
          "CMS(Content Management System)는 기업의 디지털 자산을 효율적으로 관리하고, 콘텐츠를 체계적으로 유지할 수 있게 합니다.",
        subList: [
          {
            title: "콘텐츠 유형 분석",
            description:
              "기업이 보유한 콘텐츠와 앞으로 생산할 콘텐츠의 유형을 분석합니다. 이를 통해 체계적인 관리 방안을 도출합니다.",
          },
          {
            title: "CMS 시스템 개발",
            description:
              "사용자가 쉽게 콘텐츠를 제작하고 배포할 수 있는 시스템을 구축합니다. 콘텐츠의 라이프사이클을 관리하여, 최신 정보를 지속적으로 유지합니다.",
          },
          {
            title: "사용자 교육 및 운영",
            description:
              "CMS 시스템을 사용하기 위한 교육을 진행하고, 지속적인 모니터링을 통해 시스템 성능을 유지합니다.",
          },
        ],
      },
      {
        mainTitle: "스마트팩토리 시스템",
        mainDescription:
          "스마트팩토리는 제조업의 디지털 혁신을 가능하게 하는 기술로, 모든 생산 과정을 자동화하여 효율성을 극대화합니다.",
        subList: [
          {
            title: "제조 공정 분석",
            description:
              "현재의 제조 공정을 분석하여 자동화할 수 있는 부분을 파악하고, 데이터를 기반으로 개선 방안을 도출합니다.",
          },
          {
            title: "자동화 시스템 설계",
            description:
              "생산 라인의 데이터를 실시간으로 수집하고 분석할 수 있는 자동화 시스템을 설계하여, 생산 효율성을 극대화합니다.",
          },
          {
            title: "스마트팩토리 운영",
            description:
              "자동화된 생산 라인을 운영하면서, 데이터 분석을 통해 지속적으로 효율성을 개선합니다.",
          },
        ],
      },
    ],
  },

  // 창업 마케팅 / 브랜딩 페이지
  {
    id: "2-1",
    mainPage: "startup-consulting",
    page: "startup-marketing-branding",
    title: "창업 컨설팅",
    infoList: [
      {
        mainTitle: "홍보 및 제휴 컨설팅",
        mainDescription:
          "퓨처마인드는 창업자들이 효과적으로 마케팅 채널을 구축하고  인플루언서와의 제휴를 통해 실질적인 매출 전환을 이루도록 돕습니다.",
        subList: [
          {
            title: "채널 분석 및 전략 수립",
            description:
              "성공적인 창업자들이 사용한 마케팅 채널을 분석하고, 고객의 비즈니스에 맞춘 채널 육성 전략을 수립합니다. 이를 통해 맞춤형 마케팅 계획을 세워 채널 성장을 촉진합니다.",
          },
          {
            title: "인플루언서 제휴 기획",
            description:
              "메이저 인플루언서와 협력하여 기업의 상품이나 서비스를 홍보하고, 제휴 상품을 개발합니다. 이를 통해 인지도를 높이고, 효율적인 제휴 사업을 실행합니다.",
          },
          {
            title: "매출 전환 최적화",
            description:
              "채널 육성과 인플루언서 마케팅을 통해 획득한 잠재 고객을 실제 구매 고객으로 전환할 수 있는 전략을 지속적으로 개선합니다. 이를 통해 충격적인 매출 혁신을 이루도록 돕습니다.",
          },
        ],
      },
      {
        mainTitle: "브랜딩 및 웹디자인 컨설팅",
        mainDescription:
          "전문 디자이너와 함께 고객의 브랜드 가치를 극대화하고, 웹디자인을 통해 최적화된 BX(Brand eXperience)경험을 제공합니다.",
        subList: [
          {
            title: "브랜딩 전략 수립",
            description:
              "기업의 비전과 제품의 핵심 가치를 반영한 브랜딩 전략을 수립합니다. 이를 통해 고객에게 일관된 브랜드 이미지를 전달하고, 신뢰를 구축합니다.",
          },
          {
            title: "웹디자인 퍼널 설계",
            description:
              "고객의 웹사이트 방문부터 구매까지의 과정을 체계적으로 설계하여 사용자가 쉽게 목표에 도달할 수 있도록 돕습니다. UX/UI 개선을 통해 사용자의 만족도를 극대화합니다.",
          },
          {
            title: "브랜딩 및 웹사이트 관리",
            description:
              "구축된 브랜드와 웹사이트를 지속적으로 관리하고, 트렌드와 사용자 요구에 맞추어 개선합니다. 이를 통해 고객의 충성도를 유지하고, 브랜드 가치를 더욱 높입니다.",
          },
        ],
      },
      {
        mainTitle: "자금 및 바우처,판로 컨설팅",
        mainDescription:
          "퓨처마인드는 R&D 지원사업 전문가와 자문 변리사의 도움으로 기업의 매출 성장을 지원합니다.",
        subList: [
          {
            title: "자금 지원 분석",
            description:
              "기업의 사업 단계와 필요에 맞는 정부 지원사업 및 바우처를 분석하고, 해당하는 자금 지원 프로그램을 추천합니다. 이를 통해 사업의 재정적 부담을 덜어드립니다.",
          },
          {
            title: "입찰 및 판로 개척",
            description:
              "정부 기관 및 기업을 대상으로 제안 영업을 진행하여, 기업의 입찰 기회를 극대화합니다. 입찰을 통해 안정적인 매출 채널을 확보하고, R&D 기반 기술 영업의 효과를 체감하게 합니다.",
          },
          {
            title: "기술 영업 및 판로 확대",
            description:
              "변리사의 컨설팅을 통해 기업의 기술을 보호하고, 이를 바탕으로 신규 판로를 개척합니다. 특허, 디자인권 등 지식재산권을 기반으로 한 기술 영업 전략을 제공합니다.",
          },
          {
            title: "정부 지원금을 통한 사업 운용비용 절감",
            description:
              "퓨처마인드는 다양한 정부 지원 바우처를 활용해 고객의 웹디자인, 마케팅, 지원사업 등 사업 운영 비용을 절감하고,성장을 가속화합니다.",
          },
        ],
      },
    ],
  },
  // R&D / IP 컨설팅 페이지
  {
    id: "2-2",
    mainPage: "startup-consulting",
    page: "rd-ip-consulting",
    title: "R&D\nIP 컨설팅",
    infoList: [
      {
        mainTitle: "변리 및 IP 지원사업 컨설팅",
        mainDescription:
          "저작권 및 특허, 디자인권을 통한 기업 보호 및 성장 전략 기획을 경험해보세요. 퓨처마인드의 자문 변리사 [변리법인 세움]은 기업의 지식재산권을 보호하고, 이를 바탕으로 IP 지원사업을 활용한 성장을 지원합니다.",
        subList: [
          {
            title: "IP 콘텐츠 사업 [IP 디딤돌 지원사업]",
            description:
              "기업의 기술 및 아이디어에 대한 특허, 저작권, 디자인권 등의 지식재산권을 분석하고, 보호 전략을 수립합니다.디딤돌 지원사업 등 정부의 IP 지원사업에 맞춰 고객에게 맞춤형 컨설팅을 제공합니다.",
          },
          {
            title: "IT 솔루션 사업 [IP 엑셀러레이팅]",
            description:
              "IP엑셀러레이팅, 투자전략 등 정부의 IP 지원사업에 맞춰 고객에게 맞춤형 컨설팅을 제공합니다. 이를 통해 IP 기반으로 사업 확장을 도모합니다.",
          },
        ],
      },
      {
        mainTitle: "입찰 컨설팅",
        mainDescription:
          "퓨처마인드는 기업이 정부 및 공공기관 입찰 기회를 최대한 활용할 수 있도록 종합적인 입찰 컨설팅을 제공합니다. 이를 통해 기업은 안정적인 매출원을 확보하고, 신뢰를 구축하며 성장할 수 있습니다.",
      },
    ],
  },
  // AI 평생교육원 페이지
  {
    id: "3-1",
    mainPage: "education-program",
    page: "ai-lifelong-education",
    title: "AI 평생교육원",
    infoList: [
      {
        mainTitle: "AI 평생교육원",
        mainDescription:
          "퓨처마인드 AI 평생교육원은 다양한 연령층과 기업을 대상으로 맞춤형 교육을 제공합니다. 최신 기술 트렌드를 반영한 교육 프로그램을 통해 학습자가 4차 산업 시대의 변화에 발맞추어 성장할 수 있도록 지원합니다. 각 교육 과정은 실습 중심으로 구성되어 있으며, 실질적인 성과를 도출할 수 있는 기술과 기회를 제공합니다.",
        subList: [
          {
            title: "연령별 교육 소개",
            description:
              "**청소년**\n사업기획: 진로 교육을 통해 창업과 비즈니스 마인드를 형성하고, 미래에 대한 준비를 돕습니다. 마케팅: 기술 기획 교육을 통해 디지털 마케팅 및 기술 기획 역량을 기릅니다. 웹디/코딩: 아노이두 프로그램을 통해 기초 코딩 및 웹디자인 역량을 배양합니다. GPT 영어: 실전 영어 교육을 통해 글로벌 커뮤니케이션 역량을 강화합니다. 캠프: 실감 체험 캠프를 통해 창의력과 기술 이해도를 높입니다.\n\n**신중년**\n사업기획: 창업 교육과 출판 과정을 통해 새로운 도전을 돕습니다. 마케팅: 기술 기획 교육을 통해 사업 운영과 확장을 위한 역량을 강화합니다. 웹디/코딩: 랜딩 페이지 및 퍼널 설계 교육을 통해 실질적인 비즈니스 도구를 제공합니다. GPT 영어: 영어 교육을 통해 글로벌 비즈니스 역량을 지원합니다. 캠프: 힐링 캠프를 통해 창업과 기술 학습을 결합한 경험을 제공합니다.\n\n**입주기업 및 소상공인**\n사업기획: 창업 초기부터 바우처 활용까지 비즈니스 전략을 수립합니다. 마케팅: 기술 기획과 관련된 교육을 통해 마케팅 전략을 강화합니다. 웹디/코딩: 웹디자인과 자동화 도구를 활용한 디지털 전환을 지원합니다. 캠프: 린스타트업 캠프를 통해 혁신적인 비즈니스 모델을 구축합니다.\n\n**중소벤처기업**\n 사업기획: R&D 및 해외 수출 전략 수립을 돕는 심화 교육을 제공합니다. 마케팅: 기술 기획과 R&D를 결합한 마케팅 전략 수립을 지원합니다. 웹디/코딩: AI 솔루션을 활용한 디지털 혁신을 실현합니다. 캠프: 데모데이 캠프를 통해 글로벌 진출 기회를 확대합니다.",
          },
        ],
      },
      {
        mainTitle: "분야별 교육 프로그램",
        mainDescription:
          "퓨처마인드는 기업이 정부 및 공공기관 입찰 기회를 최대한 활용할 수 있도록 종합적인 입찰 컨설팅을 제공합니다. 이를 통해 기업은 안정적인 매출원을 확보하고, 신뢰를 구축하며 성장할 수 있습니다.",
        subList: [
          {
            title: "사업기획",
            description:
              "창업과 출판에 필요한 비즈니스 전략을 교육하며, 다양한 연령층이 자신만의 사업을 구상할 수 있도록 지원합니다. 입주기업부터 중소벤처까지 맞춤형 전략 교육을 통해 성장을 도모합니다.",
          },
          {
            title: "마케팅",
            description:
              "최신 기술을 활용한 마케팅 기획 교육을 제공합니다. 소셜 미디어 마케팅, 기술 기획, 채널 전략 등을 통해 각 대상에 맞춘 마케팅 역량을 배양합니다.",
          },
          {
            title: "웹디자인 및 코딩교육",
            description:
              "코딩과 웹디자인 교육을 통해 디지털 전환을 위한 핵심 기술을 습득할 수 있습니다. AI, 웹디, 자동화 도구를 활용한 실습 과정이 포함되어 있어, 창의적이고 실질적인 학습을 할 수 있습니다.",
          },
          {
            title: "GPT 영어",
            description:
              "실전 영어 능력을 강화하여 글로벌 시장 진출을 목표로 하는 학습자들을 지원합니다. 각 연령과 수준에 맞춘 맞춤형 영어 교육이 제공됩니다.",
          },
          {
            title: "실감 체험 캠프 프그램",
            description:
              "실감 체험 및 힐링 캠프를 통해 4차 산업 기술을 직접 체험하고 학습할 수 있는 기회를 제공합니다. 창업과 기술 혁신에 대한 통합적인 경험을 할 수 있는 체험형 프로그램입니다.",
          },
        ],
      },
    ],
  },
  // 충남창업보육협회 페이지
  {
    id: "3-2",
    mainPage: "education-program",
    page: "chungnam-venture-education",
    title: "충남창업보육협회",
    infoList: [
      {
        mainTitle: "평생교육원 교육을 대학과 기관에서",
        mainDescription:
          "충남창업보육협회 및 백석메이커스와 협력하여 대학과 기관에서 AI 기술교육과 창업교육을 제공합니다. 이 프로그램은 교수와 학생들을 위한 맞춤형 교육으로, AI 기술의 실전 적용과 창업 관련 노하우를 학습할 수 있습니다. 각 교육 과정은 이론뿐만 아니라 실습을 통해 실질적인 성과를 도출할 수 있도록 설계되어 있습니다.",
      },
      {
        subList: [
          {
            title: "충남창업보육협회",
            description:
              "충남창업보육협회는 충청남도 내 창업 생태계를 활성화하고 창업자들의 성공적인 성장을 지원하기 위해 설립된 기관입니다. 이 협회는 창업 초기부터 성장 단계에 이르기까지 다양한 지원 프로그램과 교육을 통해 창업자들에게 실질적인 도움을 제공합니다.",
          },
        ],
      },
      {
        subList: [
          {
            title: "주요 역할 및 기능",
            description:
              "**창업 지원 프로그램 운영**\n충남창업보육협회는 창업자들에게 필요한 자금 지원, 경영 컨설팅, 기술 지원 등을 포함한 다양한 지원 프로그램을 운영합니다. 이를 통해 창업자들은 사업 초기의 어려움을 극복하고 지속 가능한 성장을 이룰 수 있습니다.\n\n**맞춤형 창업 교육 제공**\n대학, 연구소, 기업과 협력하여 창업을 준비하는 학생과 예비 창업자들을 대상으로 맞춤형 교육 프로그램을 제공합니다. 특히, AI 기술과 4차 산업 관련 기술 교육을 통해 창업자들이 최신 트렌드에 맞춰 비즈니스를 준비할 수 있도록 지원합니다.\n\n**네트워킹 및 제휴 기회 제공**\n협회는 창업자들이 다양한 분야의 전문가와 네트워킹할 수 있는 기회를 제공합니다. 이를 통해 창업자들은 사업 확장을 위한 제휴를 맺고, 다른 창업자들과 경험과 노하우를 공유하며 성장할 수 있습니다.\n\n**입주 공간 및 인프라 제공**\n창업자들이 비즈니스를 운영할 수 있는 사무 공간과 인프라를 제공합니다. 이러한 환경은 창업자들이 안정적으로 사업을 운영할 수 있도록 돕고, 창업 단계에서의 비용 부담을 줄이는 데 기여합니다.\n\n**기술 및 R&D 지원**\n기술 기반 창업을 준비하는 기업들을 위해 기술 개발과 R&D를 지원하며, 정부와 민간의 다양한 지원 프로그램을 연결하여 창업자들이 기술을 바탕으로 한 혁신적인 사업을 전개할 수 있도록 합니다.",
          },
        ],
      },
    ],
  },
  // 어울림 메이커스 페이지
  {
    id: "3-3",
    mainPage: "education-program",
    page: "ourim-makers",
    title: "어울림 메이커스",
    infoList: [
      {
        mainTitle: "어울림 메이커스",
        mainDescription:
          "어울림 메이커스는 퓨처마인드의 핵심 체험 공간으로, 4차 산업 기술을 활용한 다채로운 체험 활동이 이루어지는 장소입니다. 각종 첨단 기술을 실감할 수 있는 공간에서 재미와 교육을 결합한 특별한 경험을 제공합니다.",
      },
      {
        subList: [
          {
            title: "드론 체험",
            description:
              "**드론 농장 체험 (스마트팜)**\n스마트팜 기술을 활용한 드론 농장 체험을 통해 농업의 미래를 직접 경험해보세요. 드론을 조종하며 첨단 농업 기술을 익히고, 환경 친화적 농업을 체험할 수 있습니다.\n\n**드론 경주장 (스마트 물류)**\n드론 경주를 통해 물류 혁신의 첨단 기술을 즐겁게 배울 수 있는 기회! 드론 경주장에서 빠르고 정확하게 목표 지점을 통과하는 스마트 물류의 세계를 체험하세요.",
          },
        ],
      },
      {
        subList: [
          {
            title: "VR/AR 체험",
            description:
              "**AR 서바이벌 (국가보훈)**\n국가보훈을 테마로 한 AR 서바이벌 게임은 기술을 통해 역사와 국가보훈의 중요성을 체감할 수 있는 특별한 체험을 제공합니다. 증강현실 속에서 임무를 완수하며 새로운 교육 경험을 제공합니다.\n\n**AR 방탈출 (안전보건)**\n안전보건을 주제로 한 AR 방탈출 게임에서, 각종 안전문제를 해결하며 탈출을 시도하는 체험 프로그램입니다. 실생활에서 적용 가능한 안전 지식을 배울 수 있는 흥미로운 체험입니다.\n\n**로봇 풋살장 (자율주행)**\n자율주행 로봇과 함께하는 로봇 풋살장에서 즐거운 경기를 경험하세요. 최첨단 기술을 사용한 로봇과의 풋살 경기는 기술 혁신의 재미를 제공합니다. \n\n**현실 마인크래프트 (스마트 팩토리 로봇)**\n현실 세계에서 마인크래프트처럼 로봇을 활용한 스마트 팩토리 체험! 창의적이고 체계적인 문제 해결 능력을 배양하며, 로봇과 함께 미래의 공장 시스템을 이해할 수 있습니다.",
          },
        ],
      },
      {
        subList: [
          {
            title: "인플루언서 체험",
            description:
              "**AR 포토존 (실감체험)**\nAR 기술을 활용한 포토존에서 실제와 가상 세계가 결합된 특별한 사진을 찍어보세요. 인플루언서들과 함께하며 실감 나는 체험을 통해 특별한 추억을 남길 수 있습니다.\n\n**커플/가족 웨딩 틱톡 챌린지 (여성가족)**\n여성가족 테마로 진행되는 틱톡 챌린지에서 커플과 가족들이 함께 특별한 웨딩 콘텐츠를 만들며 즐거운 시간을 보낼 수 있습니다. AR 기술을 활용한 다양한 이펙트를 통해 유쾌한 경험을 제공합니다.",
          },
        ],
      },
    ],
  },
  // 미래교육문화협회 페이지
  {
    id: "3-4",
    mainPage: "education-program",
    page: "future-education-culture",
    title: "미래교육문화협회",
    infoList: [
      {
        mainTitle: "미래교육문화협회 방문 교육",
        mainDescription:
          "미래교육문화협회와 제휴하여 지역 주민들을 위한 이동형 4차산업 체험교육 프로그램을 제공합니다. 이 프로그램은 지역 내 다양한 공간에서 진행되며, 일상 생활과 가까운 곳에서 4차 산업 기술을 실감형으로 체험할 수 있는 특별한 기회를 제공합니다.\n\n**찾아가는 방문 서비스**\n 퓨처마인드는 지역 행사, 축제, 학교, 커뮤니티 공간에서 실감형 4차 산업 체험 교육을 제공합니다. 이를 통해 지역 주민들이 첨단기술을 체험하고 배울 수 있는 환경을 조성합니다.\n\n**4차산업 실감형 행사/축제/체험교육**\n 드론, VR/AR, 로봇 등 4차 산업 기술을 활용한 다양한 체험 활동이 포함되어 있습니다. 이를 통해 참가자들은 미래 기술의 가능성을 체험하고, 기술적 사고를 배양할 수 있습니다.",
      },
      {
        subList: [
          {
            title: "미래교육문화협회",
            description:
              "미래교육문화협회는 4차 산업 혁명 시대에 맞춰 교육과 문화의 융합을 통해 미래 인재를 양성하고, 기술과 창의성을 결합한 다양한 프로그램을 운영하는 비영리 단체입니다. 이 협회는 지역사회와 전국적으로 혁신적인 교육 경험을 제공하며, 미래 세대를 위한 체험형 교육을 통해 창의력과 기술적 역량을 키우는 것을 목표로 하고 있습니다.",
          },
          {
            title: "주요 역할 및 기능",
            description:
              "**4차 산업 체험 교육 프로그램 운영**\n미래교육문화협회는 4차 산업 혁명과 관련된 다양한 기술, 예를 들어 드론, 로봇, VR/AR 등의 기술을 직접 체험할 수 있는 프로그램을 제공합니다. 이러한 체험형 교육은 참가자들에게 기술에 대한 이해를 높이고, 창의적 사고를 키우는 데 중점을 둡니다.\n\n**지역사회 교육 활성화**\n협회는 전국의 지역사회와 협력하여 찾아가는 이동형 4차 산업 체험 캠프를 운영하고 있습니다. 이를 통해 다양한 계층의 사람들이 최신 기술을 접하고 배울 수 있도록 지원하며, 특히 소외된 지역 주민들이 교육 기회를 접할 수 있도록 돕습니다.\n\n**미래 인재 양성**\n미래교육문화협회는 어린이부터 청소년, 성인까지 전 연령층을 대상으로 한 맞춤형 교육 프로그램을 제공합니다. 각 연령대에 맞춘 교육을 통해 기술적 역량뿐만 아니라, 미래 사회에서 요구되는 문제 해결 능력과 협업 능력을 기를 수 있도록 지원합니다.\n\n**문화와 기술의 융합**\n 협회는 기술뿐만 아니라 문화 콘텐츠를 활용한 창의적 활동도 지원합니다. 이를 통해 교육과 문화가 결합된 다채로운 프로그램을 기획하고 운영하며, 참가자들에게 더 넓은 시각과 새로운 기회를 제공합니다.\n\n**사회 문제 해결을 위한 실감형 교육**\n미래교육문화협회는 교육을 통해 환경, 안전, 보건 등 사회 문제를 해결하기 위한 실감형 교육 프로그램도 운영합니다. 이를 통해 참가자들은 사회적 책임감을 키우고, 기술을 활용한 문제 해결 능력을 기를 수 있습니다.",
          },
        ],
      },
    ],
  },
];
